<template>
<div>
  <b-container fluid class="mb-3" v-if="!loading">
    <b-row v-if="$config.BUILD === 'full'" class="mb-4">
      <b-col>
        <b-button class="mr-3" v-if="permission.edit" variant="outline-primary" :to="{ name: 'FormEdit', params: { id: item.id, model: 'dataelement' }}">Edit</b-button>
      </b-col>
      <b-col>
        <h4>Workflow</h4>
        <div>status: <strong>{{step.name}}</strong></div>
        <div v-if="item.workflow_created_by"><small>{{item.workflow_created_by}} at {{moment(item.workflow_created_at).format('YYYY-MM-DD HH:mm')}}</small></div>
        <div>
          <span v-if="!savingStep && permission.edit">
            <b-button class="mr-4" v-if="step.downStep" variant="outline-primary" size="sm" @click="stepUpdate(step.down)">move to: {{step.downStep.name}}</b-button>
            <b-button v-if="step.upStep" variant="outline-primary" size="sm" @click="stepUpdate(step.up)">move to: {{step.upStep.name}}</b-button>
          </span>
          <span v-if="savingStep">
            <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
          </span>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h4 class="card-title"><strong>{{item.name}}</strong></h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="8">
        <div class="my-4 mr-4 p-3 shadow">
          <div class="my-2">
            <strong>Definition</strong>
          </div>
          {{ item.definition }}
        </div>
      </b-col>
      <b-col cols="4">
        <div class="my-4 mr-4 p-3 shadow">
          <treemap-simple :data="frameworkPillars" :name="''" :width="'100%'" :height="'60px'"></treemap-simple>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="8">
        <div class="my-4 mr-4 p-3 shadow">
          <div class="my-2">
            <strong>Metrics</strong>
          </div>
          <div v-if="item.metrics.length > 0">
            <div v-for="metric in item.metrics" :key="item.id + '-' + metric.id">
              <router-link :to="{ name: 'Metric', params: { id: metric.id }}">{{ metric.name }}</router-link>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="4">
        <div class="my-4 mr-4 p-3 shadow">
          <div class="my-2">
            <strong>Reporting frameworks</strong>
          </div>
          <div v-if="frameworks.length > 0">
            <div v-for="framework in frameworks" :key="'framework-'+ item.id + '-' + framework.id">
              <router-link :to="{ name: 'MetricsReportingframework', params: { id: framework.id }}">{{ framework.name }}</router-link>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="shadow p-3 bg-white" v-if="nodes.length > 0 && links.length > 0">
      <b-col class="my-4">
        <div>
          <graph-simple :nodes="nodes" :links="links" :categories="categories" :layout="'force'" :name="'Metrics and data elements graph'" :width="'100%'" :height="'50vh'"></graph-simple>
        </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import ac from '@/libs/accesscontrol'
import delay from '@/libs/delay'
import TreemapSimple from '@/components/charts/v1/treemapSimple.vue'
import GraphSimple from '@/components/charts/v1/graphSimple.vue'
import workflow from '@/workflows/metric'

function countUniqueValues (array) {
  // Count the occurrences of each value
  const counts = _.countBy(array)
  // Transform the counts object into an array of objects with name and count properties
  const result = _.map(counts, (value, name) => ({ name, value }))
  return result
}

export default {
  name: 'DataElement',
  components: {
    GraphSimple,
    TreemapSimple
  },
  computed: {
    dataelements: {
      get () {
        return this.$store.state.dataelements
      },
      set (payload) {
        this.$store.commit('setDataelements', payload)
      }
    },
    frameworkPillars: function () {
      const pillars = []
      this.metrics.forEach(metric => {
        metric.taxonomy.forEach(taxonomy => {
          taxonomy.parents.forEach(parent => {
            pillars.push(parent.name)
          })
        })
      })
      const results = countUniqueValues(pillars)
      return results
    },
    frameworks: function () {
      let results = []
      this.metrics.forEach(metric => {
        metric.reportingframeworks.forEach(framework => {
          results.push(framework)
        })
      })
      results = _.orderBy(_.uniqBy(results, 'id'), ['name'], ['asc'])
      return results
    },
    metrics: function () {
      const allMetrics = this.$store.state.metrics
      let results = []
      if (this.item.metrics) {
        this.item.metrics.forEach(metric => {
          results.push(allMetrics.find(item => item.id === metric.id))
        })
      }
      results = _.orderBy(results, ['name'], ['asc'])
      return results
    },
    nodes: function () {
      let nodes = []
      nodes.push({ id: 'dataelement-' + this.item.id, name: this.item.name, category: 0, symbolSize: 20 })
      this.metrics.forEach(metric => {
        nodes.push({ id: 'metric-' + metric.id, name: metric.name, category: 1 })
        metric.reportingframeworks.forEach(framework => {
          nodes.push({ id: 'framework-' + framework.id, name: framework.name, category: 2 })
        })
      })
      nodes = _.uniqBy(nodes, 'id')
      return nodes
    },
    links: function () {
      let links = []
      this.metrics.forEach(metric => {
        links.push({ source: 'dataelement-' + this.item.id, target: 'metric-' + metric.id, uuid: 'dataelement-' + this.item.id + 'metric-' + metric.id })
        metric.reportingframeworks.forEach(framework => {
          links.push({ source: 'metric-' + metric.id, target: 'framework-' + framework.id, uuid: 'metric-' + metric.id + 'framework-' + framework.id })
        })
      })
      links = _.uniqBy(links, 'uuid')
      return links
    },
    step: function () {
      const step = workflow.find(x => x.id === this.item.workflow_id)
      step.downStep = workflow.find(x => x.id === step.down)
      step.upStep = workflow.find(x => x.id === step.up)
      return step
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.permission.edit = ac.can(this.user.acgroups).updateAny('metric').granted
    this.load()
  },
  data () {
    const data = {
      categories: [
        {
          name: 'Data element'
        },
        {
          name: 'Metric'
        },
        {
          name: 'Reporting framework'
        }
      ],
      item: {},
      loading: true,
      moment: moment,
      permission: {
        delete: false,
        edit: false
      },
      savingStep: false
    }
    return data
  },
  methods: {
    load: async function () {
      this.loading = true
      if (this.dataelements.length === 0) {
        await this.loadMetric()
      } else {
        this.item = this.dataelements.find(item => item.id === Number.parseInt(this.$route.params.id))
        if (this.item === undefined) {
          await this.loadElement()
        }
      }
      await delay(100)
      this.loading = false
      this.loadElement()
    },
    loadElement: async function () {
      try {
        const response = await this.$Amplify.API.get('cosmos', `/dataelement/${this.$route.params.id}`)
        this.item = response
        if (this.dataelements.length > 0) {
          const index = this.dataelements.findIndex(x => x.id === this.item.id)
          if (index === -1) {
            this.dataelements.push(this.item)
          } else {
            this.dataelements[index] = this.item
          }
        }
      } catch (e) {
        this.$logger.warn('event loading error: ' + e)
      }
    },
    stepUpdate: async function (id) {
      this.savingStep = true
      try {
        const params = {
          body: {
            workflow_id: id,
            workflow_created_at: moment().toISOString(),
            workflow_created_by: this.user.username
          }
        }
        await this.$Amplify.API.put('cosmos', `/standard/dataelement/${this.item.id}`, params)
        this.item.workflow_id = id
        this.item.workflow_created_by = params.body.workflow_created_by
        this.item.workflow_created_at = params.body.workflow_created_at
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.savingStep = false
    }
  },
  watch: {
    '$route': function () {
      this.load()
    }
  }
}
</script>
